<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-'+RESOURCES_ELEMENT_NAME+'-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template v-if="Object.keys(elementData).length > 0">
      <element-edit-tab
        :new-element="newElement"
        :element-data="elementData"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        :type="TYPE"
        :son-type="SON_TYPE"
        :parent-type="PARENT_TYPE"
        :parent-id="PARENT_ID"
        :deep="DEEP"
        class="mt-2 pt-75"
      />
    </template>
  </div>
</template>

<script>
import {
   BAlert, BLink
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      DEEP: 1,
      TYPE: '',
      SEARCH_TYPE: '',
      PARENT_TYPE: '',
      PARENT_ID: undefined,
      SON_TYPE: '',
      newElement: true,
      elementData: {}
    }
  },
  mounted() {
    this.SON_TYPE = (router.currentRoute.params.son_type && router.currentRoute.params.son_type != 'undefined') ? router.currentRoute.params.son_type : ''
    this.DEEP |= router.currentRoute.params.deep
    this.PARENT_ID = router.currentRoute.params.parent_id ? router.currentRoute.params.parent_id.toString() : ''
    this.PARENT_TYPE = router.currentRoute.params.parent_type || ''
    this.TYPE = router.currentRoute.params.type
    this.SEARCH_TYPE = router.currentRoute.params.search_type
    this.RESOURCES_ELEMENT_NAME = this.TYPE
    this.ELEMENT_APP_STORE_MODULE_NAME = 'apps-ent-ed-' + this.$t(this.RESOURCES_ELEMENT_NAME + '.module_name')
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    
    if (router.currentRoute.params.id !== undefined) {
      this.fetchElement()
    } else {
      this.elementData = {
        parent: router.currentRoute.params.parent_id,
        deep: router.currentRoute.params.deep
      }
    }
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchElement() {
      this.newElement = false
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
        .then(response => { 
          this.elementData = response.data 
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.elementData = undefined
          }
        })

    },
    save(elementData) {
      let method = this.ELEMENT_APP_STORE_MODULE_NAME + (this.newElement ? '/add_element' : '/save_element')
      let action = this.newElement ? 'creation' : 'update'
      var _self = this
      store
        .dispatch(method, {type: this.SEARCH_TYPE, data: elementData})
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.success'),
            },
          })
          this.$router.push({ name: 'apps-entity-list' })
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.failure') +'\n' +error.response.data.message,
            },
          })
        })
    }
  }
}
</script>

<style>

</style>
