<template>
  <b-card>
    <b-card-header>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
      <span class="font-weight-bold">{{$t(resourcesElementName + '.name')}}</span>
      </b-col>
    </b-card-header>
    <b-card-body>
    
      <validation-observer 
        ref="elementForm"
        #default="{ invalid }"
        >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group
                  label-for="ID"
              >
                <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                />
                <span class="font-weight-bold">{{$t(resourcesElementName + '.object.id')}}</span><span class="text-danger">*</span>
                <validation-provider
                    #default="{ errors }"
                    name="ID"
                    rules="required"
                  >
                  <b-form-input
                    id="ID"
                    v-model="elementData.key"
                    maxlength="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <b-form-group
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(resourcesElementName + '.object.name')}}</span><span class="text-danger">*</span>
                <validation-provider
                    #default="{ errors }"
                    name="nombre"
                    rules="required"
                    maxlength="100"
                  >
                  <b-form-input
                    id="nombre"
                    v-model="elementData.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end">
            <router-link v-if="sonType && elementData.id" :to="{name: 'apps-'+$t(sonType + '.module_name')+'-list', params: { parent_id: elementData.id }}">
              <b-button 
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              >
              {{$t('actions.see')}} {{$t(sonType + '.plural_name')}}
              </b-button>
            </router-link>
            <b-button v-if="type"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="router().go(-1)"
            >
            {{$t('actions.back')}}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
            {{$t('actions.save')}}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from "@/router";

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver 
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    sonType: {
      type: String,
      required: true,
    },
    deep: {
      type: Number,
      required: true,
    },
    parentType: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      required
    }
  },
  methods: {
    router() {
      return router
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$parent.save(this.elementData);
        }
      })
    }
  }
}
</script>

<style>

</style>
